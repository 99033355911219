/* @import '~antd/dist/antd.css'; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/********/

/* ... existing styles ... */

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.sidebar {
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  padding: 16px 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.sidebar::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.sidebar li {
  border-block-end: none !important;
}

.chat-container {
  padding: 88px 24px 124px 24px;
  position: relative;
  min-height: calc(100vh);
}

.chat {
  /* background: pink; */
  /* height: calc(100vh - 175px); */
  /* overflow-y: scroll; */
  padding: 0 12px;
}


.chat-message {
  display: inline-block;
  position: relative;
  margin-bottom: 8px;
  padding: 10px 14px;
  border-radius: 15px;
  font-size: 16px;
  max-width: 60%;
  line-height: 1.5;
}

.user-message {
  background-color: #D4DCFF;
  color: #333;
  float: right;
  clear: both;
}

.user-message:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-left-color: #D4DCFF;
  border-right: 0;
  margin-top: -6px;
  margin-left: 0;
  top: 50%;
  left: 100%;
}

.bot-message {
  background-color: #1B21C4;
  color: #E7E7F3;
  float: left;
  clear: both;
}

.bot-message:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-right-color: #1B21C4;
  border-left: 0;
  margin-top: -6px;
  margin-right: 0;
  top: 50%;
  right: 100%;
}

.data-list li {
  border-bottom: 0;
}